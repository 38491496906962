/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useRef, useState } from "react"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { observer } from "mobx-react-lite"
import AudioPlayer, { FilterFileContext } from "@medaica/common/components/audio-player"
import moment from "moment"
import { Auscultation } from "@medaica/common/types"
import useGlobalContext from "@medaica/common/hooks/global-context"
import MedaicaFilters from "@medaica/common/components/audio-player/filters"
import { AlgorithmType, buildAudioFileFromAuscultation } from "@medaica/common/services/factories"
import { WAFFileContext } from "@medaica/common/components/audio-player/web-audio-filters"

const AuscultationDetails = observer(
  ({
    auscultation,
    prefilteringAlgorithm,
  }: {
    expanded: boolean
    auscultation: Auscultation
    onContract: () => void
    prefilteringAlgorithm: AlgorithmType
  }): ReactElement => {
    const { medaicaApiService } = useGlobalContext()
    const audioFileRef = useRef(buildAudioFileFromAuscultation(auscultation, medaicaApiService, prefilteringAlgorithm))
    const filterContextRef = useRef<FilterFileContext>(new WAFFileContext())
    const [filtersDisabled, setFiltersDisabled] = useState<boolean>(true)

    const handleFileDownloaded = () => {
      setFiltersDisabled(false)
    }
    const handleFiltersChangedEvent = (data: string) => {
      // Handle the event and data received from changed filter types
    }

    return (
      <div className="p-3">
        <div className="flex flex-col">
          <h4 className="font-semibold header-color">{auscultation.auscultationPoint.longLabel}</h4>
          <div className="text-sm text-gray-on-gray">{moment(auscultation.dateCreated).format("lll")}</div>
          Filters: {"Medaica"}
          <br />
          Prefiltering: {prefilteringAlgorithm.toString()}
          <br />
          <div className="flex mt-2">
            <figure>
              <AuscultationPointImage
                highlightedPoint={auscultation.auscultationPoint}
                className="h-28 w-28 border border-gray-200 rounded-md bg-gray-50"
                style={{ padding: "2px" }}
              />
            </figure>

            <div className="flex-grow ml-3 panel border p-5 bg-gray-50">
              <div>
                <div className="flex space-x-5">
                  <MedaicaFilters
                    filterContext={filterContextRef.current}
                    onFiltersChangeEvent={handleFiltersChangedEvent}
                    disableSetting={filtersDisabled}
                  />
                </div>
              </div>

              <div className="mt-4">
                <div className="bg-black mb-1" />
                <div className="mt-3">
                  <AudioPlayer
                    noiseQualityRecord={auscultation.noiseQualityRecord}
                    filterContext={filterContextRef.current}
                    audioFile={audioFileRef.current}
                    onFileDownloaded={handleFileDownloaded}
                    auscultationPointType={auscultation.auscultationPoint.type}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default AuscultationDetails
