/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import Header from "@medaica/common/components/page/page-header"
import useGlobalContext from "@medaica/common/hooks/global-context"
import useAuth from "hooks/auth"
import { Exam, ExamPrevNextIds } from "@medaica/common/types"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Loading from "@medaica/common/components/loading"
import moment from "moment"
import ExamDetails from "views/exam/exam-details/components/exam-details"
import { useSnackbar } from "notistack"
import Page from "@medaica/common/components/page/page"
import { Button, Box, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { logError } from "@medaica/common/services/util"
import { Paths } from "const"

const ExamView = (): ReactElement => {
  const { examId } = useParams<{ examId: string }>()
  const [exam, setExam] = useState<Exam | null>(null)
  const [examTitle, setExamTitle] = useState<string>()
  const [examIds, setExamIds] = useState<ExamPrevNextIds>()
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const displayHiddenInfo = "**********"
  const navigate = useNavigate()
  const location = useLocation()
  const showButtons =
    location.pathname.includes(Paths.organizationExams) || location.pathname.includes(Paths.organizationExamsAll)
  const allExams = location.pathname.includes(Paths.organizationExamsAll)

  useEffect(() => {
    const fetchData = async () => {
      if (medaicaApiService && examId) {
        const exam = await medaicaApiService.exams.getExamById(examId, ["patientProfile", "auscultations"])
        setExamTitle(
          user.organizationId === exam.patientProfile.organizationId ? exam.patientProfile.fullName : displayHiddenInfo
        )
        setExam(exam)
        if (showButtons) {
          const examIds = await medaicaApiService.exams.getExamPrevNext(examId, allExams)
          setExamIds(examIds)
        }
      }
    }
    void fetchData()
  }, [allExams, enqueueSnackbar, examId, medaicaApiService, showButtons, user.organizationId])

  const handlePrevExam = () => {
    // Navigate to the previous exam
    // which is older than the current exam
    // Note: it's more intuitive to Next button to navigate to older exams.
    // So we reversed the logic here.
    if (examIds?.prevId) {
      try {
        const targetPath = allExams
          ? `${Paths.organizationExamsAll}/${examIds.prevId}`
          : `${Paths.organizationExams}/${examIds.prevId}`
        navigate(targetPath)
      } catch (error) {
        logError(error)
      }
    }
  }

  const handleNextExam = () => {
    // Navigate to the next exam
    // which is newer than the current exam
    // Note: it's more intuitive to Previous button to navigate to newer exams.
    // So we reversed the logic here.
    if (examIds?.nextId) {
      try {
        const targetPath = allExams
          ? `${Paths.organizationExamsAll}/${examIds.nextId}`
          : `${Paths.organizationExams}/${examIds.nextId}`
        navigate(targetPath)
      } catch (error) {
        logError(error)
      }
    }
  }

  return exam ? (
    <Page variant="full" key={exam.id}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={examTitle} subtitle={moment(exam.dateCreated).format("LLL")} />
        {showButtons && (
          <Box display="flex" flexDirection="column" alignItems="center">
            {examIds && examIds?.examSequenceNumber > 0 && examIds?.totalExams > 0 && (
              <Typography variant="subtitle1">
                {examIds?.examSequenceNumber} of {examIds?.totalExams}
              </Typography>
            )}
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={handleNextExam}
                disabled={!examIds?.nextId}
                sx={{ marginRight: 1 }}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                onClick={handlePrevExam}
                disabled={!examIds?.prevId}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <ExamDetails exam={exam} />
    </Page>
  ) : (
    <Loading />
  )
}

export default ExamView
