/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

enum Paths {
  home = "/",
  exams = "/exams",
  selfExamRequests = "/self-exam-requests",
  liveExam = "/live-exam",
  virtualExam = "/virtual-exam",
  liveExamCalendar = "/calendar",
  liveExamCompatibilityTest = "/live-exam-compatibility-test",
  patients = "/patients",
  councilOfDoctors = "/council",
  forgotPassword = "/forgot-password",
  authCallback = "/auth-callback",
  postAuthPipeline = "/post-auth-pipeline",
  updateProfile = "/update-profile",
  errors_404 = "/errors/404",
  hipaa = "/hipaa",
  support = "/support",
  contact = "/contact",
  terms = "/terms",
  privacy = "/privacy",
  loggedOut = "/signed-out",
  logOut = "/sign-out",
  emsLog = "/ems-log",
  createAccount = "/create-account",
  profileAndSettings = "/profile-and-settings",
  profileAndSettingsPersonalInfo = "/profile-and-settings/personal-info",
  profileAndSettingsPersonalInfoEdit = "/profile-and-settings/personal-info/edit",
  profileAndSettingsEmailAddress = "/profile-and-settings/email-address",
  profileAndSettingsPassword = "/profile-and-settings/password",
  organizationHealthcareProviders = "/organization/healthcare-providers",
  organizationPatients = "/organization/patients",
  organizationExams = "/organization/exams",
  organizationExamsAll = "/organization/exams/all",
}

const footerNavigation = [
  { name: "About Medaica", href: "https://www.medaica.com", newWindow: true },
  { name: "Support", href: Paths.support, newWindow: true },
  { name: "Contact", href: Paths.contact, newWindow: true },
  { name: "Terms", href: Paths.terms, newWindow: true },
  { name: "Privacy", href: Paths.privacy, newWindow: true },
  { name: "HIPAA", href: Paths.hipaa, newWindow: true },
]

export { Paths, footerNavigation }
