/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Header from "@medaica/common/components/page/page-header"
import { Paths } from "const"
import moment from "moment"
import Table, { buildActionColumn } from "@medaica/common/components/table/table"
import Page from "@medaica/common/components/page/page"
import { Exam } from "@medaica/common/types"
import useAuth from "hooks/auth"
import useQuery from "@medaica/common/hooks/query"
import { Switch, Typography } from "@mui/material"
import ViewDetailsButton from "@medaica/common/components/table/view-details-button"
import ExamStatusChip from "views/exam/components/exam-status-chip"
import { MUIDataTableData } from "mui-datatables"
import { logError, mapExamType } from "@medaica/common/services/util"
import Loading from "@medaica/common/components/loading"

type ExamData = {
  date: string
  patient: string
  patientId: string
  type: string
  reviewed: boolean
  conductedBy: string
  actions: Exam
}

const ExamListView = (): ReactElement => {
  const { setCurrentPage, medaicaApiService } = useGlobalContext()
  const [error, setError] = useState<string>()
  const [exams, setExams] = useState<ExamData[] | null>(null)
  const [count, setCount] = useState<number>()
  const { user } = useAuth()
  const pageNumber = useQuery("p")
  const allExams = useQuery("all")
  const chipEls: HTMLDivElement[] = []
  const [examsSwitchStatus, setExamsSwitchStatus] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setExamsSwitchStatus(allExams === "true")
    setCurrentPage("organization", Paths.organizationExams)
  }, [allExams, setCurrentPage])

  /**
   * We want the chips to be the same width. Thus, every time the table updates, we determine the largest chip width.
   *  Once we have that value, we set the width of all the chips to that width.
   */
  const setChipWidths = () => {
    const largestWidth = chipEls.reduce((acc, el) => {
      return Math.max(acc, el.getBoundingClientRect().width)
    }, 0)
    chipEls.forEach((el) => {
      el.style.width = `${largestWidth}px`
    })
  }

  const fetchData = useCallback(
    async (pageNumber: number): Promise<void> => {
      try {
        const page =
          allExams === "true"
            ? await medaicaApiService.exams.getExams(
                {
                  sort: [{ field: "dateCreated", direction: "desc" }],
                  joinedLoads: ["patient", "patientProfile", "healthcareProvider"],
                  pagination: { pageNumber: pageNumber, pageSize: 10 },
                },
                false,
                true
              )
            : await medaicaApiService.exams.getExams({
                filters: [{ field: "healthcareProviderId", value: user.id }],
                sort: [{ field: "dateCreated", direction: "desc" }],
                joinedLoads: ["patient", "patientProfile", "healthcareProvider"],
                pagination: { pageNumber: pageNumber, pageSize: 10 },
              })
        setCount(page.totalCount)
        setExams(
          page.items.map((exam) => {
            return {
              date: moment(exam.dateCreated).format("lll"),
              patient: exam.patientFullName,
              patientId: exam.patientProfile.id,
              type: mapExamType(exam.type.toString()),
              reviewed: exam.reviewed,
              conductedBy: exam.healthcareProvider?.displayName,
              actions: exam,
            } as ExamData
          })
        )
      } catch (e) {
        logError(error)
        setError(
          "An unexpected error occurred. Our team has been notified and is looking into it. Please try again later."
        )
      }
    },
    [allExams, error, medaicaApiService.exams, user.id]
  )

  useEffect(() => {
    void fetchData(pageNumber ? parseInt(pageNumber) : 1)
  }, [fetchData, pageNumber])

  const PatientCell = ({ dataIndex }: { dataIndex: number }) => {
    if (!exams || !exams[dataIndex]) {
      return <Typography component="span"></Typography>
    }

    return (
      <Typography component="span" sx={{ fontWeight: "600" }}>
        <Link className="link" to={`${Paths.patients}/${exams[dataIndex].patientId}`}>
          {exams[dataIndex].patient}
        </Link>
      </Typography>
    )
  }

  const examsSwitchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const isAllOrganizationExams: boolean = event.target.checked
    setExamsSwitchStatus(isAllOrganizationExams)
    const updateURL = `${Paths.organizationExams}?all=${isAllOrganizationExams}&p=${1}`
    navigate(updateURL, { replace: true })
  }

  return (
    <Page variant="full">
      <Header title="Exams" />
      <div
        style={{ display: "flex", alignItems: "center", marginLeft: "15px", marginBottom: "10px", marginTop: "-20px" }}
      >
        <Typography>My Exams</Typography>
        <dd style={{ marginLeft: "0px" }}>
          <Switch
            checked={examsSwitchStatus}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => examsSwitchChanged(event)}
          />
        </dd>
        <Typography>All</Typography>
      </div>
      {exams && (
        <Table
          data={exams as unknown as MUIDataTableData[]}
          // https://medaica.atlassian.net/browse/MD-279
          title=""
          options={{
            onTableChange: setChipWidths,
            serverSide: true,
            count: count,
            download: false,
            print: false,
          }}
          columns={[
            {
              name: "patient",
              label: "Patient name",
              options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => <PatientCell dataIndex={dataIndex} />,
              },
            },
            {
              label: "Exam date",
              name: "date",
              options: {
                filter: true,
                sort: true,
              },
            },
            {
              name: "type",
              label: "Exam type",
              options: {
                sort: true,
              },
            },
            {
              name: "reviewed",
              label: "Status",
              options: {
                sort: true,
                customBodyRender: (isReviewed) => ExamStatusChip({ isReviewed, chipEls }),
              },
            },
            {
              name: "conductedBy",
              label: "Conducted by",
              options: {
                filter: true,
                sort: true,
              },
            },
            buildActionColumn("actions", (exam: Exam) => (
              <ViewDetailsButton
                to={
                  allExams === "true"
                    ? `${Paths.organizationExamsAll}/${exam.id}`
                    : `${Paths.organizationExams}/${exam.id}`
                }
              />
            )),
          ]}
        />
      )}
      {error && <div style={{ textAlign: "center" }}>{error}</div>}
      {!exams && !error && <Loading />}
    </Page>
  )
}

export default ExamListView
