/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useRef, useState } from "react"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { observer } from "mobx-react-lite"
import AudioPlayer, { AudioPlayerState } from "@medaica/common/components/audio-player"
import { ChevronUpIcon } from "@heroicons/react/solid"
import moment from "moment"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { MedaicaFilters } from "@medaica/common/components/audio-player/filters"
import { Auscultation } from "@medaica/common/types"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { AlgorithmType, buildAudioFileFromAuscultation } from "@medaica/common/services/factories"
import ActionMenu from "views/exam/virtual-exam/exam-room/components/auscultation-request/components/review-auscultation-view/components/action-menu"
import { WAFFileContext } from "@medaica/common/components/audio-player/web-audio-filters"

const AuscultationDetailsExpanded = observer(
  ({ auscultation, onContract }: { auscultation: Auscultation; onContract: () => void }): ReactElement => {
    const { medaicaApiService } = useGlobalContext()
    const { virtualExamStore } = useContext(VirtualExamContext)
    const audioFileRef = useRef(buildAudioFileFromAuscultation(auscultation, medaicaApiService, AlgorithmType.Silence))
    const filterContextRef = useRef<WAFFileContext>(new WAFFileContext())
    const [filtersDisabled, setFiltersDisabled] = useState<boolean>(true)

    const handleFileDownloaded = () => {
      setFiltersDisabled(false)
    }

    const handleFiltersChangedEvent = (data: string) => {
      // Handle the event and data received from changed filter types
    }
    const handleAudioPlayerStateChanged = (state: AudioPlayerState) => {
      virtualExamStore.setIsReviewingAuscultation(state === "playing")
    }

    return (
      <div className="panel bg-gray-100 p-2">
        <div className="flex">
          <figure className="mb-2 flex flex-col">
            <AuscultationPointImage
              highlightedPoint={auscultation.auscultationPoint}
              className="w-28 h-28 border border-gray-200 rounded-md bg-gray-50"
              style={{ padding: "2px" }}
            />
          </figure>
          <div className="flex flex-col flex-grow">
            <div className="flex flex-grow items-start">
              <div className="ml-3 flex-grow">
                <h4 className="font-semibold header-color">{auscultation.auscultationPoint.longLabel}</h4>
                <div className="text-sm text-gray-on-gray">{moment(auscultation.dateCreated).format("lll")}</div>
              </div>
              <ActionMenu auscultationId={auscultation.id} />
              <button onClick={onContract}>
                <ChevronUpIcon className="w-6 mr-1" />
              </button>
            </div>
            <div className="overflow-hidden mt-2">
              <div
                className="relative ml-2 text-sm px-4 py-2 bg-gray-50 rounded-t-md border-t border-l border-r
            rl-corner"
              >
                <div className="grid grid-cols-2 gap-y-1.5 gap-x-2 mt-1">
                  <MedaicaFilters
                    filterContext={filterContextRef.current}
                    onFiltersChangeEvent={handleFiltersChangedEvent}
                    disableSetting={filtersDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clear-both" style={{ marginTop: "-12px" }}>
          <div className="mt-3 panel border p-3 bg-gray-50 rounded-tr-none">
            <div className="bg-black mb-1" />
            <AudioPlayer
              noiseQualityRecord={auscultation.noiseQualityRecord}
              filterContext={filterContextRef.current}
              audioFile={audioFileRef.current}
              onStateChanged={handleAudioPlayerStateChanged}
              onFileDownloaded={handleFileDownloaded}
              auscultationPointType={auscultation.auscultationPoint.type}
            />
          </div>
        </div>
      </div>
    )
  }
)

export default AuscultationDetailsExpanded
